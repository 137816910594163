body {
  background: url('/img/2.png') no-repeat center center fixed;
  background-size: cover;
  color: #0a78c5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.container {
  flex: 1;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  margin: 2rem auto;
  padding: 2.5rem 2rem;
  max-width: 800px;
  transition: all 0.3s ease;
}

.container.outer-container {
  padding: 0.2rem 2rem;
}

.brand-name {
  color: #0a78c5;
  font-weight: 700;
  background: linear-gradient(120deg, #0a78c5, #05a2c2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 0 2px;
}

.logo-container {
  transform: translateY(0);
  transition: transform 0.3s ease;
}

.logo-container:hover {
  transform: translateY(-5px);
}

.email-input {
  background: white;
  border: 2px solid #e1e1e1;
  border-radius: 8px;
  font-size: 16px;
  padding: 12px 16px;
  transition: all 0.2s ease;
  width: 100%;
}

.email-input:focus {
  border-color: #0a78c5;
  box-shadow: 0 0 0 3px rgba(10, 120, 197, 0.2);
  outline: none;
}

.submit-button {
  background: #0a78c5;
  border: none;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 24px;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.submit-button:hover {
  background: #0862a0;
  transform: translateY(-1px);
}

.submit-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.social-icons {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 2.5rem;
  padding-bottom: 1rem;
}

.social-icon {
  transition: transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-icon:hover {
  transform: scale(1.1);
}

footer {
  text-align: right;
  padding: 8px 16px;
  margin: 1rem;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 20px;
  color: white;
  max-width: fit-content;
  margin-left: auto;
}

@media screen and (max-width: 768px) {
  .container {
    margin: 0;
    padding: 1.25rem;
    border-radius: 0;
  }
  
  .logo-container {
    margin-bottom: 1.5rem !important;
  }
  
  .logo-container img {
    width: 150px;
  }

  .d-flex.flex-column.flex-md-row {
    gap: 12px !important;
  }
  
  .submit-button {
    width: 100%;
    padding: 12px 16px;
  }
  
  footer {
    text-align: center;
    margin: 0;
    padding: 1rem;
    border-radius: 0;
    max-width: none;
    margin-left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
  }

  h1 {
    font-size: 1.25rem !important;
    margin-bottom: 1rem !important;
  }

  p {
    font-size: 0.95rem;
    margin-bottom: 0.75rem !important;
  }
}

.alert {
  border-radius: 8px;
  padding: 1rem;
  margin: 1rem 0;
  animation: slideIn 0.3s ease;
}

.alert-success {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
